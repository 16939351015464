import { NotificationsService, SaveSubscriptionDto } from '../API';

export async function subscribeUserToPush(updateMe: () => void) {
  if (!('serviceWorker' in navigator)) {
    console.error('[subscribeUserToPush] No Service Worker support!');
    return;
  }
  const registration = await navigator.serviceWorker.ready;
  // 'granted', 'default', 'denied'
  let permission = Notification?.permission;
  if (permission === 'default') {
    permission = await window.Notification.requestPermission();
  }
  if (permission !== 'granted') {
    console.error('[subscribeUserToPush] User declined to receive notifications');
    return;
  }

  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: 'BNTazptIwStOnX3F1U97Mm2jiD0B-LnSCymQjp0crjueyFRXfrUgluYFkqRqkK0W1qWbLVPGi0b-CajX17amLMQ',
  });

  await NotificationsService.saveSubscription({
    requestBody: subscription as unknown as SaveSubscriptionDto,
  });

  navigator.serviceWorker.onmessage = (event) => {
    if (event.data && event.data.type === 'NEW_NOTIFICATION') {
      updateMe();
    }
  };
}
